import React from "react";
import HomeLandingText from "../HeroLandingText";
import { Link } from "gatsby";
import { Headline80 } from "../styles/Headings";
import OutlinedText from "../styles/OutlinedText";
import SliderStackStudio from "../publishers/SliderStackStudio";
import HeroTitleOutline from "../HeroOutlineTitle";
import HeroDivideRight from "../HeroDivideRight";
import TextHighlight from "../styles/TextHighlight";
import { white } from "../styles/Colors";

const PublishersContentStudioStatic = () => {
  return (
    <>
      <HomeLandingText
        section="Content Studio"
        link={{
          label: "Get Started",
          attrs: { to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }
        }}
        cleanTitle={true}
        theme={{ topPadding: "78px", bottomPadding: "160px" }}
      >
        <Headline80 as="h1">EXPERTS IN COMMERCE</Headline80>
        <OutlinedText component={Headline80} as="span">
          STORYTELLING
        </OutlinedText>
      </HomeLandingText>

      <SliderStackStudio />

      <HeroTitleOutline
        theme={{
          marginTop: 0,
          marginBottom: "-44px",
          paddingBottom: "0",
          paddingTop: "136px",
          paddingTopMobile: "76px"
        }}
        title="Scale your"
        subtitle="Commerce content"
      />

      <HeroDivideRight contentTag="div" theme={{}}>
        <p>
          We make it simple to write and publish more commerce content without
          leaning on any internal editorial resources.
        </p>
        <p>
          {" "}
          Our team of expert writers produces original,{" "}
          <TextHighlight theme={{ background: white }}>
            data-informed
          </TextHighlight>{" "}
          commerce content that turns readers into buyers.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default PublishersContentStudioStatic;

import React from "react";
import styled, { keyframes } from "styled-components";
import { brandColor2 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import { graphql, useStaticQuery } from "gatsby";
import { useInView } from "react-intersection-observer";
import uniqueId from "lodash/uniqueId";
import Container from "../styles/Container";

export const scrollLeftDesktop = keyframes`
  0% {
    transform: translateX(-1528px);
  }
  100% {
    transform: translateX(-3056px);
  }
`;

export const scrollLeftMedia = keyframes`
  0% {
    transform: translateX(-1008px);
  }
  100% {
    transform: translateX(-2016px);
  }
`;

const Wrapper = styled.div`
  background-color: ${brandColor2};
  overflow: hidden;
  position: relative;
`;

const Div = styled(Container)`
  white-space: nowrap;
  max-height: 470px;
  transform: translate3d(0, 0, 0);

  &:before {
    content: "";
    display: inline-block;
    padding-bottom: 165%;
  }

  @media (${mediaMedium}) {
    max-height: 680px;
  }
`;

const Grid = styled.div`
  position: absolute;
  top: 96px;
  left: 0;
  display: flex;
  align-items: flex-end;
  transform: translateX(0%);
  animation: ${scrollLeftMedia} 20s linear infinite;
  animation-play-state: ${(props) => (props.active ? "running" : "paused")};

  @media (${mediaMedium}) {
    animation-name: ${scrollLeftDesktop};
    animation-duration: 15s;
  }
`;

const Item = styled.div`
  width: 230px;
  margin: 0 11px;
  flex: 0 0 230px;

  img {
    height: auto;
    border: none;
    outline: none;
    display: block;
    user-select: none;
    margin: 0;
    max-width: 100%;
    max-height: none;
    width: 100%;
  }

  &.desktop {
    display: none;
  }

  @media (${mediaMedium}) {
    width: 360px;
    flex: 0 0 360px;

    &.desktop {
      display: block;
    }
  }
`;

const threshold = [0, 0.1, 0.2, 0.5, 0.75];

const SliderStackStudioSection = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: threshold,
    delay: 30
  });

  return (
    <Wrapper ref={ref}>
      <Div>
        <Grid active={inView}>
          {data &&
            data.allFile.edges.map((item, key) => (
              <Item key={uniqueId("unit" + key)}>
                <img
                  alt=""
                  draggable="false"
                  width={item.node.childImageSharp.fluid.maxWidth}
                  height={item.node.childImageSharp.fluid.maxHeight}
                  sizes={item.node.childImageSharp.fluid.maxHeight}
                  src={item.node.childImageSharp.fluid.src}
                  srcSet={item.node.childImageSharp.fluid.srcSet}
                />
              </Item>
            ))}
          {data &&
            data.allFile.edges.map((item, key) => (
              <Item key={uniqueId("unit" + key)}>
                <img
                  alt=""
                  draggable="false"
                  width={item.node.childImageSharp.fluid.maxWidth}
                  height={item.node.childImageSharp.fluid.maxHeight}
                  sizes={item.node.childImageSharp.fluid.maxHeight}
                  src={item.node.childImageSharp.fluid.src}
                  srcSet={item.node.childImageSharp.fluid.srcSet}
                />
              </Item>
            ))}
          {data &&
            data.allFile.edges.map((item, key) => (
              <Item key={uniqueId("unit" + key)}>
                <img
                  alt=""
                  draggable="false"
                  width={item.node.childImageSharp.fluid.maxWidth}
                  height={item.node.childImageSharp.fluid.maxHeight}
                  sizes={item.node.childImageSharp.fluid.maxHeight}
                  src={item.node.childImageSharp.fluid.src}
                  srcSet={item.node.childImageSharp.fluid.srcSet}
                />
              </Item>
            ))}
          {data &&
            data.allFile.edges.map((item, key) => (
              <Item className="desktop" key={uniqueId("unit" + key)}>
                <img
                  alt=""
                  draggable="false"
                  width={item.node.childImageSharp.fluid.maxWidth}
                  height={item.node.childImageSharp.fluid.maxHeight}
                  sizes={item.node.childImageSharp.fluid.maxHeight}
                  src={item.node.childImageSharp.fluid.src}
                  srcSet={item.node.childImageSharp.fluid.srcSet}
                />
              </Item>
            ))}
        </Grid>
      </Div>
    </Wrapper>
  );
};

// maxWidth: 360, maxHeight: 594,
const SliderStackStudio = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { regex: "/24-stack-studio-slide-[0-9]/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `);
  return <SliderStackStudioSection data={data} />;
};

export default SliderStackStudio;

import React from "react";
import styled from "styled-components";
import { white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import MainHeroTitle from "./MainHeroTitle";
import Container from "./styles/Container";
import { Eyebrows20 } from "./styles/Eyebrows";

const ContainerRelative = styled(Container)`
  position: relative;
  padding-top: 38px;
  padding-bottom: 100px;

  @media (${mediaMedium}) {
    padding-top: ${(props) => props.theme.topPadding ?? "38px"};
    padding-bottom: ${(props) => props.theme.bottomPadding ?? "142px"};
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  background-color: ${white};
  overflow: hidden;
`;

const SubTitle = styled(Eyebrows20)`
  margin: 0 0 28px;
`;

const HomeLandingText = ({ link, theme, cleanTitle, children, section }) => {
  return (
    <Wrapper>
      <ContainerRelative theme={theme}>
        {section && <SubTitle as="span">{section}</SubTitle>}
        <MainHeroTitle
          cleanTitle={cleanTitle}
          theme={{ titleWidth: "100%" }}
          label={link.label}
          link={link.attrs}
        >
          {children}
        </MainHeroTitle>
      </ContainerRelative>
    </Wrapper>
  );
};

export default HomeLandingText;

import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersContentStudioStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersContentStudioDefer")
);

const ContentStudio = () => (
  <Layout>
    <Seo
      title="Full-Service Commerce Content Creation"
      description="Scale your commerce content program risk-free with affordable articles that convert. Our team of writers is an extension of your team!"
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default ContentStudio;
